import React, { useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShareIcon from '@mui/icons-material/Share';
import TagIcon from '@mui/icons-material/Tag';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { contractTabLabel, contractTableTabLabel } from './DocLibStaticData';
import ShareModal from './ShareModal/ShareModal';
import AddCoOwner from './TableComponents/AddCoOwner';
import DocumentLibraryTable from './TableComponents/DocumentLibraryTable';
import ReplaceAndEditTag from './TableComponents/ReplaceAndEditTag';
import UploadContractDoc from './TableComponents/UploadContractDoc';
import UploadSupportFile from './TableComponents/UploadSupportFile';
import {
  getKeyCloakRealmFromLS,
  getSessionStorage,
} from '../../Authentication/Actions/authentication';
import StaticTab from '../../Draft/Component/TableTabList';
import { IObject } from '../../interfaces/IObject';
import { a11yProps } from '../../RiverusUI/Components/CustomTabPanel';
import ReusableConfirmationModal from '../../RiverusUI/Components/ReusableConfirmationModal';
import {
  bulkDeleteDocs,
  fetchDocLibContracts,
  fetchHeaderCounts,
} from '../../Services/DocumentLibrary';

const initialFilter = {
  end_date: null,
  projects: null,
  title: null,
  groups: null,
  contract_type: null,
  contracting_parties: null,
  status: null,
};

const DocumentLibraryComponent: React.FC = () => {
  const [openUploadContract, setOpenUploadContract] = useState<boolean>(false);
  const [openUploadSupportDoc, setOpenUploadSupportDoc] =
    useState<boolean>(false);
  const [activeTab, setActiveTab] = useState('all');
  const [activeHeader, setActiveHeader] = useState<string>('contracts_count');
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openReplaceModal, setOpenReplaceModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openCoOwnerModal, setOpenCoOwnerModal] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [filters, setFilters] = useState<IObject>(initialFilter);
  const [contractsTableKey, setContractsTableKey] = useState<number>(0);
  const [sorting, setSorting] = useState<GridSortModel>([]);

  const queryClient = useQueryClient();

  const realm_name = getKeyCloakRealmFromLS();

  const { enqueueSnackbar } = useSnackbar();

  const user_data = useMemo(() => getSessionStorage('user_profile'), []);

  const userIsAdmin = useMemo(
    () => user_data?.roles?.includes('admin'),
    [user_data?.roles]
  );

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const { data: headerData } = useQuery({
    queryKey: ['contract_header_count'],
    queryFn: async () => {
      const response = await fetchHeaderCounts();
      return response?.contracts_statistic;
    },
  });

  const { data: contractTableData, isLoading: isLoadingTable } = useQuery({
    queryKey: [
      'contract_table_data',
      filters,
      contractsTableKey,
      activeTab,
      activeHeader,
      pageNumber,
      sorting,
    ],
    queryFn: async () => {
      let params = '';

      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }

      let filterTypeParam = '';
      if (activeHeader && activeHeader !== 'contracts_count') {
        filterTypeParam = `&${activeHeader}=true`;
      }

      let sortingParam = '';

      if (sorting.length > 0) {
        for (let i = 0; i < sorting.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}?ordering=${sorting[i].field}`;
          } else {
            sortingParam = `${sortingParam}?ordering=-${sorting[i].field}`;
          }
        }
      } else {
        sortingParam = '?ordering=-created_on';
      }

      if (activeTab === 'all') {
        params = `${sortingParam}&page=${
          pageNumber + 1
        }${filterParam}${filterTypeParam}`;
      } else if (activeTab === 'active') {
        params = `${sortingParam}&page=${
          pageNumber + 1
        }&active=true${filterParam}${filterTypeParam}`;
      } else if (activeTab === 'expired') {
        params = `${sortingParam}&page=${
          pageNumber + 1
        }&expired=true${filterParam}${filterTypeParam}`;
      } else if (activeTab === 'my_contracts') {
        params = `${sortingParam}&page=${
          pageNumber + 1
        }&created_by=${realm_name}__${
          user_data?.email
        }${filterParam}${filterTypeParam}`;
      }

      const response = await fetchDocLibContracts(params);
      return response;
    },
  });

  const handleCloseUploadContract = () => {
    setOpenUploadContract(false);
  };

  const handleCloseSupportDoc = () => {
    setOpenUploadSupportDoc(false);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCloseReplaceModal = () => {
    setOpenReplaceModal(false);
  };

  const handleHeaderChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setPageNumber(0);
    setActiveHeader(newValue);
    setFilters({});
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setPageNumber(0);
    setActiveTab(newValue);
    setFilters({});
  };

  const handleRefresh = () => {
    setContractsTableKey(Math.random());
    setFilters(initialFilter);
    queryClient.invalidateQueries({ queryKey: ['contract_header_count'] });
  };

  const { mutate: deleteBulkUploadedDocs } = useMutation({
    mutationKey: ['delete_bulk_uploaded_docs'],
    mutationFn: bulkDeleteDocs,
    onSuccess: () => {
      enqueueSnackbar('Documents deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      handleRefresh();
      handleCloseDeleteModal();
    },
    onError: () => {
      enqueueSnackbar('Failed to delete all documents!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const userCanDoAction = useMemo(() => {
    const filterContracts = contractTableData?.results?.filter(
      (contractData: any) => selectedRow?.includes(contractData?.id)
    );

    const userIsContractCreator = filterContracts?.every(
      (item: any) => item?.creator_details?.id === user_data?.id
    );

    const userIsOwner = filterContracts?.every((item: any) =>
      item?.owners?.some((ownerItem: any) => ownerItem?.id === user_data?.id)
    );

    if (userIsContractCreator || userIsOwner || userIsAdmin) {
      return true;
    }
    return false;
  }, [contractTabLabel, selectedRow, userIsAdmin, user_data]);

  const handleOpenSupportModal = () => {
    if (selectedRow?.length) {
      setOpenUploadSupportDoc(true);
    } else {
      enqueueSnackbar('Please select any contract', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleOpenEdit = () => {
    if (userCanDoAction) {
      setOpenEditModal(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleOpenReplaceModal = () => {
    if (userCanDoAction) {
      setOpenReplaceModal(true);
    } else {
      if (selectedRow?.length === 1) {
        enqueueSnackbar(
          'You are not the owner of the contract. Owner or Admin can perform this action',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      } else {
        enqueueSnackbar(
          'You are not the owner for all the contracts, please bulk select contracts where you are the owner for all.',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      }
    }
  };

  const handleOpenShareModal = () => {
    if (userCanDoAction) {
      setOpenShareModal(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleOpenDeleteModal = () => {
    if (userCanDoAction) {
      setOpenDeleteModal(true);
    } else {
      if (selectedRow?.length === 1) {
        enqueueSnackbar(
          'You are not the owner of the contract. Owner or Admin can perform this action',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      } else {
        enqueueSnackbar(
          'You are not the owner for all the contracts, please bulk select contracts where you are the owner for all.',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      }
    }
  };

  const handleOpenCoOwnerModal = () => {
    if (userCanDoAction) {
      setOpenCoOwnerModal(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleOpenUploadContract = () => {
    setOpenUploadContract(true);
  };

  return (
    <Stack>
      <Stack className="sidebar-right-column" gap="16px">
        <Stack spacing="16px" direction="column">
          <Stack
            direction="row"
            spacing="60px"
            sx={{
              width: '100%',
              overflowX: 'scroll',
              scrollbarWidth: 'none',
            }}
          >
            <Stack direction="column" spacing="10px">
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Upload
              </Typography>
              <Stack direction="row" spacing="15px">
                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: 'riPrimary.500',
                    padding: '8px 16px',
                    borderRadius: '6px',
                    fontSize: '11px',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: 1,
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={handleOpenUploadContract}
                >
                  <UploadFileIcon fontSize="small" />
                  <br />
                  {selectedRow?.length === 1
                    ? 'Amendment Contract'
                    : 'Contract'}
                </Button>
                <Button
                  sx={{
                    color: 'riPrimary.500',
                    padding: '8px 16px',
                    borderRadius: '6px',
                    fontSize: '11px',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: 1,
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                  variant="outlined"
                  onClick={handleOpenSupportModal}
                >
                  <FileUploadOutlinedIcon fontSize="small" />
                  <br />
                  Support document
                </Button>
              </Stack>
            </Stack>
            <Stack direction="column" spacing="10px">
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                All Contracts
              </Typography>
              <Tabs
                aria-label="all contracts tabs"
                variant="scrollable"
                scrollButtons={false}
                sx={{ marginTop: '4px' }}
                value={activeHeader}
                onChange={handleHeaderChange}
              >
                {headerData &&
                  Object.entries(headerData)?.map(([key], index) => {
                    return (
                      <StaticTab
                        key={index}
                        count={`${headerData[key]} ${
                          contractTabLabel[key] === 'Used space' ? 'MB' : ''
                        }`}
                        label={contractTabLabel[key]}
                        value={key}
                        {...a11yProps(key)}
                      />
                    );
                  })}
              </Tabs>
            </Stack>
          </Stack>
          {selectedRow?.length > 0 ? (
            <Stack direction="row" justifyContent="end" alignItems="center">
              {selectedRow?.length === 1 && (
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleOpenCoOwnerModal}
                >
                  Add Co-Owner
                </Button>
              )}
              {selectedRow?.length === 1 ? (
                <Button startIcon={<TagIcon />} onClick={handleOpenEdit}>
                  Add / Edit tags
                </Button>
              ) : (
                <Button
                  startIcon={<TagIcon />}
                  onClick={handleOpenReplaceModal}
                >
                  Add / Replace tags
                </Button>
              )}
              {selectedRow?.length === 1 && (
                <Button
                  startIcon={<ShareIcon />}
                  onClick={handleOpenShareModal}
                >
                  Share
                </Button>
              )}
              <Button
                startIcon={<DeleteOutlineIcon />}
                onClick={handleOpenDeleteModal}
              >
                Delete
              </Button>
            </Stack>
          ) : (
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Tabs
                  aria-label="All contracts tabs"
                  value={activeTab}
                  onChange={handleTabChange}
                >
                  {Object.entries(contractTableTabLabel)?.map(
                    ([key], index) => (
                      <Tab
                        key={index}
                        label={contractTableTabLabel[key]}
                        value={key}
                        {...a11yProps(key)}
                      />
                    )
                  )}
                </Tabs>
                <IconButton onClick={handleRefresh}>
                  <RefreshIcon />
                </IconButton>
              </Stack>
            </Box>
          )}
          <DocumentLibraryTable
            tableData={contractTableData}
            pageNumber={pageNumber}
            setPageNumberChange={setPageNumber}
            setSelectedRow={setSelectedRow}
            setFilters={setFilters}
            filters={filters}
            isLoading={isLoadingTable}
            setSorting={setSorting}
          />
        </Stack>
      </Stack>

      {openUploadContract && (
        <UploadContractDoc
          open={openUploadContract}
          onClose={handleCloseUploadContract}
          handleRefresh={handleRefresh}
          selectedRow={selectedRow}
        />
      )}

      {openUploadSupportDoc && (
        <UploadSupportFile
          open={openUploadSupportDoc}
          onClose={handleCloseSupportDoc}
          handleRefresh={handleRefresh}
          selectedRow={selectedRow}
          allContracts={contractTableData?.results || []}
        />
      )}

      {openCoOwnerModal && (
        <AddCoOwner
          open={openCoOwnerModal}
          onClose={() => setOpenCoOwnerModal(false)}
          contractId={selectedRow}
        />
      )}

      {openEditModal && (
        <ReplaceAndEditTag
          open={openEditModal}
          onClose={handleCloseEditModal}
          selectedIds={selectedRow}
          replace={false}
          title="Add / Edit Tags"
        />
      )}

      {openReplaceModal && (
        <ReplaceAndEditTag
          open={openReplaceModal}
          onClose={handleCloseReplaceModal}
          selectedIds={selectedRow}
          replace={false}
          title="Add / Replace Tags"
        />
      )}

      {openShareModal && (
        <ShareModal
          openModal={openShareModal}
          handleCloseShareBtn={() => setOpenShareModal(false)}
          selectedList={selectedRow}
          type="umbrella"
        />
      )}

      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Document"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => deleteBulkUploadedDocs(selectedRow)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected document will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </Stack>
  );
};

export default DocumentLibraryComponent;
