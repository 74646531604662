import React, { useState } from 'react';

import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Chip, ChipProps, Popover, Stack } from '@mui/material';

import AutocompleteElement from '../RiverusUI/Components/AutoCompleteElement';
import { FormInputProps } from '../RiverusUI/Interfaces/FormInputProps';

interface IProps extends FormInputProps {
  isMultiselect?: boolean;
  name: string;
  control: any;
  renderCustomComponent?: (value: string, props?: ChipProps) => React.ReactNode;
  loading?: boolean;
  showCheckbox?: boolean;
  labelKey?: string;
  valueKey?: string;
}

const FilterInput: React.FC<IProps> = ({
  isMultiselect = false,
  name,
  control,
  renderCustomComponent,
  loading = false,
  showCheckbox = true,
  label,
  options,
  labelKey = 'name',
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  return (
    <AutocompleteElement
      label={label}
      name={name}
      multiple={isMultiselect}
      options={options || []}
      matchId
      showCheckbox={showCheckbox}
      control={control}
      loading={loading}
      autocompleteProps={{
        renderTags: (value, getTagProps) =>
          renderCustomComponent ? (
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              sx={{
                overflow: 'scroll',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {value
                .slice(0, 1)
                .map((option: any, index) =>
                  renderCustomComponent(option, { ...getTagProps({ index }) })
                )}
              {value?.length > 1 && (
                <Chip
                  label={`${value.length - 1}+`}
                  onClick={handleClick}
                  sx={{
                    background: '#FFECF1',
                    color: '#6D264C',
                    padding: 0,
                    borderRadius: '5px',
                    margin: '3px',
                  }}
                />
              )}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Stack spacing={1.5} sx={{ padding: '10px' }}>
                  {value.slice(1).map((option: any, index) => {
                    return (
                      <Chip
                        key={index + 1}
                        label={option[labelKey]}
                        onDelete={getTagProps({ index: index + 1 }).onDelete}
                        sx={{
                          background: '#FFECF1',
                          color: '#6D264C',
                          borderRadius: '5px',
                          justifyContent: 'start',
                        }}
                        icon={
                          name === 'filter_by_group' ? (
                            <GroupWorkOutlinedIcon
                              style={{
                                color: '#6D264C',
                              }}
                            />
                          ) : name === 'filter_by_user' ? (
                            <PersonOutlineOutlinedIcon
                              style={{
                                color: '#6D264C',
                              }}
                            />
                          ) : undefined
                        }
                      />
                    );
                  })}
                </Stack>
              </Popover>
            </Stack>
          ) : null,
      }}
      {...props}
    />
  );
};

export default FilterInput;
