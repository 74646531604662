import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import ReminderForm from './ReminderForm';
import { iconColor, status_icon } from './ReminderStatic';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import { status_bg_color } from '../../../Reminder/ReminderStaticData';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import NameAvatar from '../../../RiverusUI/DataGrid/NameAvatar';
import {
  deleteComment,
  deleteReminder,
  fetchContractById,
  fetchReminder,
} from '../../../Services/DocumentLibrary';
import {
  addContractReminderBulkComments,
  addContractReminderComments,
  fetchReminderById,
} from '../../../Services/Reminder';
import Scrollable from '../../../UniversalComponents/Scrollable/scrollable';

interface Props {
  fileId: string;
}

const ReminderTab: React.FC<Props> = ({ fileId }) => {
  const { handleSubmit, control, reset } = useForm();
  const user_data = React.useMemo(() => getSessionStorage('user_profile'), []);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [reminderEditData, setReminderEditData] = useState<any>({});
  const [viewOnly, setViewOnly] = useState<boolean>(false);
  const [showInput, setShowInput] = useState<{ [key: string]: boolean }>({});
  const [isCommentHovered, setIsCommentHovered] = useState<{
    [key: string]: boolean;
  }>({});
  const [accordionExpanded, setAccordionExpanded] = useState<any>('');
  const [showCommentsInput, setShowCommentsInput] = useState<any>('');
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const queryClient = useQueryClient();

  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const refs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const queryParamsValue = queryParams.get('isReminder');
  const reminderId = queryParams.get('reminderId') || '';

  const handleCloseForm = () => {
    if (reminderEditData) setReminderEditData({});
    if (viewOnly) setViewOnly(false);
    if (queryParamsValue === 'true') {
      navigate(pathname);
    }
    setOpenForm(false);
  };

  const { data: contractData } = useQuery({
    queryKey: ['get_contract_data_by_id'],
    queryFn: async () => await fetchContractById(fileId),
    enabled: !!fileId,
  });

  const { data: reminderList } = useQuery({
    queryKey: ['get_reminders'],
    queryFn: async () => {
      const response = await fetchReminder(contractData?.id);
      return response?.results;
    },
    enabled: !!contractData?.id,
  });

  const { data: reminderById } = useQuery({
    queryKey: ['get_reminder_by_id'],
    queryFn: async () => await fetchReminderById(reminderId),
    enabled: !!reminderId,
  });

  useEffect(() => {
    if (queryParamsValue === 'true' && reminderById) {
      setViewOnly(true);
      setOpenForm(true);
      setReminderEditData(reminderById);
    }
  }, [queryParamsValue, reminderById]);

  const { mutate: delete_reminder, isPending } = useMutation({
    mutationKey: ['delete-reminder'],
    mutationFn: deleteReminder,
    onSuccess: () => {
      enqueueSnackbar('Reminder deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to delete Reminder!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: delete_comment } = useMutation({
    mutationKey: ['delete-comment'],
    mutationFn: deleteComment,
    onSuccess: () => {
      enqueueSnackbar('Comment deleted successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      setIsButtonClicked(false);
    },
    onError: () => {
      enqueueSnackbar('Failed to delete Comment!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const isOwner = contractData?.creator_details?.id === user_data?.id;

  const userIsCoOwner = useMemo(
    () =>
      contractData?.owners?.find((owner: any) => owner?.id === user_data?.id),
    [contractData?.owners, user_data]
  );

  const userIsAdmin = useMemo(
    () => user_data?.roles?.includes('admin'),
    [user_data?.roles]
  );

  const handleClick = () => {
    if (!isOwner && !userIsAdmin && !userIsCoOwner) {
      enqueueSnackbar(
        'You are not the owner of the contract. Please contact owner to create a reminder. ',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    } else {
      setOpenForm(true);
    }
  };

  const toggleInput = useCallback(
    (itemId: string) => {
      if (isButtonClicked) {
        setIsButtonClicked(false);
      }
      setShowInput((prevState) => {
        if (showCommentsInput) {
          return {
            ...prevState,
            [itemId]: false,
          };
        }
        return prevState;
      });
      setShowInput((prevState) => {
        if (showCommentsInput !== itemId) {
          return {
            ...prevState,
            [itemId]: !prevState[itemId] || false,
          };
        }
        return prevState;
      });
      setShowCommentsInput('');
    },
    [setShowInput, showCommentsInput, isButtonClicked]
  );

  const handleButtonClick = (approverData: any) => {
    const filteredApprovers =
      reminderList?.filter((list: any) => list?.id === approverData?.id) || [];

    const filteredApproversId =
      reminderList
        ?.filter((list: any) => list?.id === approverData?.id)
        ?.map((list: any) => list?.id) || [];

    const isComments = filteredApprovers?.some(
      (approver: any) => approver?.comments?.length > 0
    );
    handleCloseForm();
    if (isComments) {
      setIsButtonClicked(true);
      handleCommentsClick(filteredApprovers[0]);
      setAccordionExpanded(approverData?.id);
    } else {
      setShowCommentsInput(filteredApproversId[0]);
    }
  };

  const { mutate: addReminderCommentsMutation } = useMutation({
    mutationFn: addContractReminderComments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['reminder_comments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
      reset();
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.comment?.[0];
      enqueueSnackbar(`${responseData || 'Failed to add comments!'}`, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: addBulkReminderCommentsMutation } = useMutation({
    mutationKey: ['contract_bulk_comments'],
    mutationFn: addContractReminderBulkComments,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['reminder_comments'],
      });
      queryClient.invalidateQueries({
        queryKey: ['get_reminders'],
      });
    },
  });

  const commentsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [reminderList]);

  const handleCommentsClick = useCallback(
    (clickedItem: any) => {
      if (isButtonClicked) {
        setIsButtonClicked(false);
      }
      const bulkCommentIds = clickedItem?.comments
        ?.filter((comment: any) => !comment.is_read)
        ?.map((comment: any) => comment.id);

      const isOwner = clickedItem?.created_by?.id === user_data?.id;
      const hasUnreadCommentsByOthers = clickedItem?.comments?.some(
        (comment: any) =>
          comment?.commented_by?.id !== user_data?.id &&
          !comment?.is_read &&
          clickedItem?.users?.some(
            (user: any) => user?.id === comment?.commented_for
          )
      );

      const canCallApiAsOwner = !isOwner && hasUnreadCommentsByOthers;
      const canCallApiAsNonOwner =
        isOwner &&
        bulkCommentIds.length > 0 &&
        clickedItem?.comments?.some(
          (comment: any) =>
            !comment?.is_read &&
            comment?.commented_by?.id !== clickedItem?.created_by?.id
        );

      if (
        bulkCommentIds.length > 0 &&
        (canCallApiAsOwner || canCallApiAsNonOwner)
      ) {
        const payload = {
          ids: bulkCommentIds,
          is_read: 'true',
        };
        addBulkReminderCommentsMutation(payload);
      }

      if (commentsContainerRef.current) {
        commentsContainerRef.current.scrollTop =
          commentsContainerRef.current.scrollHeight;
      }
    },
    [addBulkReminderCommentsMutation, user_data?.id, isButtonClicked]
  );

  const onSubmit = useCallback(
    (data: any, remindersId: string) => {
      const commentedById = user_data?.id;
      const commentedForIds = reminderList
        ?.find((reminder: any) => reminder?.id === remindersId)
        ?.users.find((user: any) => user?.id);

      const payload = {
        comment: data[`comment-${remindersId}`],
        reminder: remindersId,
        commented_by: commentedById,
        commented_for: commentedForIds?.id,
      };
      toggleInput(remindersId);
      addReminderCommentsMutation(payload);
    },
    [toggleInput, reminderList, addReminderCommentsMutation, user_data?.id]
  );

  const handleCommentHover = (commentId: string, isHovered: boolean) => {
    setIsCommentHovered((prevState) => ({
      ...prevState,
      [commentId]: isHovered,
    }));
  };

  const userCanDoAction = useMemo(
    () => isOwner || userIsAdmin || userIsCoOwner,
    [isOwner, userIsAdmin, userIsCoOwner]
  );

  const currentApprovalId = useMemo(() => {
    return reminderList?.find(
      (value: any) =>
        value?.id === showCommentsInput || value?.id === accordionExpanded
    )?.id;
  }, [reminderList, showCommentsInput, accordionExpanded]);

  //  On re-routing scroll to the corresponding approval card
  useEffect(() => {
    const element = refs.current[currentApprovalId];
    if (currentApprovalId && element) {
      setTimeout(() => {
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          });
        }
      }, 300);
    }
  }, [isButtonClicked, currentApprovalId]);

  return (
    <React.Fragment>
      <Button
        startIcon={<AddIcon />}
        className="according-btn"
        style={{ justifyContent: 'start' }}
        onClick={handleClick}
      >
        Add a new Reminder
      </Button>

      <Scrollable maxHeight="85vh" minHeight="85vh">
        {reminderList?.map((item: any) => {
          const isPastDue = dayjs(item?.due_date).isBefore(dayjs(), 'day');
          return (
            <Stack
              key={item?.id}
              ref={(el) => {
                refs.current[item.id] = el;
              }}
              className="according-class"
              padding="15px"
              spacing={3}
            >
              <Stack spacing={1.5}>
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography>{item?.name}</Typography>
                </Stack>
                <Stack
                  width="100%"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row" spacing={1}>
                    <CustomChip
                      sx={{
                        background: isPastDue ? '#F2B8B5' : '#FFFAFA',
                        padding: '14px 10px',
                        borderRadius: '5px',
                        '& .MuiChip-label': {
                          paddingRight: '0',
                          paddingLeft: '4px',
                          fontSize: '12px',
                        },
                        '& .MuiChip-icon': {
                          fontSize: '15px',
                          margin: '0',
                        },
                      }}
                      icon={<WatchLaterOutlinedIcon />}
                      label={dayjs(item?.due_date).format('MMMM D, YYYY')}
                    />
                    <CustomChip
                      sx={{
                        background: status_bg_color?.[item?.status],
                        padding: '14px 5px',
                        borderRadius: '5px',
                        border: '1px solid #79747E',
                        '& .MuiChip-label': {
                          paddingRight: '0',
                          paddingLeft: '4px',
                          fontSize: '12px',
                        },
                        '& .MuiChip-icon': {
                          fontSize: '15px',
                          margin: '0',
                          color: iconColor[item?.status],
                        },
                        '&:hover': {
                          background: '#fff',
                        },
                      }}
                      icon={status_icon[item?.status]}
                      label={item?.status}
                    />
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    {item?.users?.map((userItem: any) => (
                      <NameAvatar
                        key={userItem?.id}
                        firstName={userItem?.first_name}
                        lastName={userItem?.last_name}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>

              <Stack spacing={3}>
                <Stack spacing={2}>
                  {item?.comments?.length > 0 && (
                    <Accordion
                      sx={{
                        background: 'unset',
                        boxShadow: 'none',
                      }}
                      expanded={accordionExpanded === item?.id}
                    >
                      <AccordionSummary
                        sx={{
                          padding: '0 12px',
                          backgroundColor: '#fbe3e9',
                          borderRadius:
                            accordionExpanded === item?.id
                              ? '10px 10px 0 0'
                              : '10px ',
                        }}
                        onClick={() => {
                          handleCommentsClick(item);
                          setAccordionExpanded((prev: any) =>
                            prev === item?.id ? null : item?.id
                          );
                        }}
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: '#6D264C' }} />
                        }
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1.5}
                        >
                          <Typography
                            variant="body2"
                            fontWeight={600}
                            textTransform="uppercase"
                            color="#6D264C"
                          >
                            Comments
                          </Typography>

                          {item?.unread_comments_count > 0 &&
                            item?.comments?.some(
                              (comment: any) =>
                                !comment?.is_read &&
                                comment?.commented_by?.id !== user_data?.id &&
                                item?.users?.some(
                                  (user: any) =>
                                    user?.id !== item?.created_by?.id
                                )
                            ) && (
                              <Typography
                                variant="caption"
                                fontWeight={600}
                                color="#A0597F"
                                border="2px solid"
                                borderRadius="10px"
                                padding="2px 6px"
                                sx={{ borderStyle: 'dashed' }}
                              >
                                {item?.unread_comments_count > 1
                                  ? `${item?.unread_comments_count} New Messages`
                                  : `${item?.unread_comments_count} New Message`}
                              </Typography>
                            )}
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          padding: '0 12px 16px',
                          backgroundColor: '#fbe3e9',
                          borderRadius: '0 0 10px 10px',
                        }}
                      >
                        <Stack spacing={2}>
                          <Scrollable
                            maxHeight={250}
                            ref={commentsContainerRef}
                          >
                            <Stack spacing={1}>
                              {item?.comments
                                ?.slice()
                                .reverse()
                                .map((comment: any) => (
                                  <Stack
                                    key={comment.id}
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    onMouseEnter={() =>
                                      handleCommentHover(comment.id, true)
                                    }
                                    onMouseLeave={() =>
                                      handleCommentHover(comment.id, false)
                                    }
                                  >
                                    {comment?.commented_by?.id !==
                                      user_data?.id && (
                                      <NameAvatar
                                        firstName={
                                          comment?.commented_by?.first_name
                                        }
                                        lastName={
                                          comment?.commented_by?.last_name
                                        }
                                      />
                                    )}
                                    {comment?.comment?.split(/\s+/)?.length >
                                    10 ? (
                                      <Tooltip title={comment?.comment} arrow>
                                        <TextField
                                          value={comment?.comment}
                                          disabled
                                          fullWidth
                                        />
                                      </Tooltip>
                                    ) : (
                                      <TextField
                                        value={comment?.comment}
                                        disabled
                                        fullWidth
                                      />
                                    )}
                                    {comment?.commented_by?.id ===
                                      user_data?.id && (
                                      <NameAvatar
                                        firstName={
                                          comment?.commented_by?.first_name
                                        }
                                        lastName={
                                          comment?.commented_by?.last_name
                                        }
                                      />
                                    )}
                                    {isCommentHovered[comment.id] &&
                                      comment?.commented_by?.id ===
                                        user_data?.id && (
                                        <DeleteOutlineIcon
                                          sx={{
                                            cursor: 'pointer',
                                            fontSize: '20px',
                                            color: '#6D264C',
                                          }}
                                          onClick={() =>
                                            delete_comment(comment?.id)
                                          }
                                        />
                                      )}
                                  </Stack>
                                ))}
                            </Stack>
                          </Scrollable>

                          {item?.comments?.length > 0 &&
                            (item?.users?.some(
                              (userItem: any) => userItem?.id === user_data?.id
                            ) ||
                              userCanDoAction) && (
                              <Stack
                                component="form"
                                spacing={1}
                                direction="row"
                                alignItems="center"
                                onSubmit={handleSubmit((data) =>
                                  onSubmit(data, item?.id)
                                )}
                              >
                                <ControlledTextField
                                  name={`comment-${item?.id}`}
                                  control={control}
                                  fullWidth
                                  className={
                                    accordionExpanded === item?.id &&
                                    !openForm &&
                                    isButtonClicked
                                      ? 'blink-border-animation'
                                      : ''
                                  }
                                  sx={{
                                    background: '#FFF7FA',
                                    borderRadius: '6px',
                                    border:
                                      accordionExpanded === item?.id &&
                                      !openForm &&
                                      isButtonClicked
                                        ? '2px solid transparent'
                                        : '',
                                  }}
                                />
                                <NameAvatar
                                  firstName={user_data.first_name}
                                  lastName={user_data.last_name}
                                />
                              </Stack>
                            )}

                          {item?.comments?.length > 0 &&
                            (item?.users?.some(
                              (userItem: any) => userItem?.id === user_data?.id
                            ) ||
                              userCanDoAction) && (
                              <Link
                                fontSize="14px"
                                display="flex"
                                alignItems="center"
                                whiteSpace="nowrap"
                                sx={{
                                  cursor: 'pointer',
                                  textDecoration: 'unset',
                                }}
                              >
                                <ChatBubbleOutlineIcon
                                  sx={{
                                    mr: '6px',
                                    fontSize: '14px',
                                    marginTop: '0.4rem',
                                  }}
                                />
                                Add/ Reply Comment
                              </Link>
                            )}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  {item?.comments?.length === 0 &&
                    (item?.users?.map(
                      (userItem: any) => userItem?.id === user_data?.id
                    ) ||
                      userCanDoAction) &&
                    (showCommentsInput === item?.id || showInput[item?.id]) && (
                      <Stack
                        component="form"
                        spacing={1}
                        direction="row"
                        alignItems="center"
                        onSubmit={handleSubmit((data) =>
                          onSubmit(data, item?.id)
                        )}
                      >
                        <ControlledTextField
                          name={`comment-${item?.id}`}
                          control={control}
                          fullWidth
                          className={
                            showCommentsInput === item?.id && !openForm
                              ? 'blink-border-animation'
                              : ''
                          }
                          sx={{
                            background: '#FFF7FA',
                            borderRadius: '6px',
                            border:
                              showCommentsInput === item?.id && !openForm
                                ? '2px solid transparent'
                                : '',
                          }}
                        />
                        <NameAvatar
                          firstName={user_data.first_name}
                          lastName={user_data.last_name}
                        />
                      </Stack>
                    )}
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  flexWrap="wrap"
                >
                  {item?.comments?.length === 0 &&
                    (item?.users?.some(
                      (userItem: any) => userItem?.id === user_data?.id
                    ) ||
                      userCanDoAction) && (
                      <Link
                        onClick={() => toggleInput(item?.id)}
                        fontSize="14px"
                        display="flex"
                        alignItems="center"
                        whiteSpace="nowrap"
                        sx={{ cursor: 'pointer', textDecoration: 'unset' }}
                      >
                        <ChatBubbleOutlineIcon
                          sx={{
                            mr: '6px',
                            fontSize: '14px',
                            marginTop: '0.4rem',
                          }}
                        />
                        Add/Reply Comment
                      </Link>
                    )}
                  <Stack direction="row" spacing={2} sx={{ ml: 'auto' }}>
                    {(item?.users?.some(
                      (userItem: any) => userItem?.id === user_data?.id
                    ) ||
                      userCanDoAction) && (
                      <Button
                        variant="outlined"
                        startIcon={<BorderColorIcon />}
                        sx={{ padding: '5px 15px', borderRadius: '10px' }}
                        onClick={() => {
                          setReminderEditData(item);
                          setOpenForm(true);
                        }}
                      >
                        Edit
                      </Button>
                    )}
                    {userCanDoAction && (
                      <LoadingButton
                        loading={isPending}
                        variant="outlined"
                        startIcon={<DeleteOutlineIcon />}
                        sx={{ padding: '5px 15px', borderRadius: '10px' }}
                        onClick={() => delete_reminder(item?.id)}
                      >
                        Delete
                      </LoadingButton>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          );
        })}
      </Scrollable>

      {openForm && (
        <CustomModal
          maxWidth="md"
          title={`${reminderEditData?.id ? 'Edit' : 'Create'} Reminder`}
          open={openForm}
          handleClose={handleCloseForm}
        >
          <ReminderForm
            onClose={handleCloseForm}
            contractData={contractData}
            reminderEditData={reminderEditData}
            viewOnly={viewOnly}
            setViewOnly={setViewOnly}
            user_data={user_data}
            queryParamsValue={queryParamsValue}
            handleButtonClick={handleButtonClick}
            setIsButtonClicked={setIsButtonClicked}
            isButtonClicked={isButtonClicked}
          />
        </CustomModal>
      )}
    </React.Fragment>
  );
};

export default ReminderTab;
