import React, { useMemo } from 'react';

import { Box, Button, Stack, Typography } from '@mui/material';
import { Control } from 'react-hook-form';

import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import colors from '../../../RiverusUI/Theme/colors';
import { sortListAlphabetical } from '../Helper';

interface Props {
  control: Control;
  assigneeData: any[];
  onSubmit?: any;
  userIsOwner: boolean;
}

const AddAssignee: React.FC<Props> = ({
  control,
  assigneeData,
  onSubmit,
  userIsOwner,
}) => {
  const user_data: any = useMemo(() => getSessionStorage('user_profile'), []);

  const userIsAdmin = useMemo(
    () => user_data?.roles?.includes('admin'),
    [user_data?.roles]
  );

  return (
    <Box
      sx={{ backgroundColor: colors?.riPrimary[10], flexGrow: 1 }}
      padding={2}
    >
      <Typography padding={1} fontSize="16px" fontWeight="500">
        Add Co-Owner
      </Typography>
      <RISelectComponent
        name="assignee"
        control={control}
        label="Select user"
        options={sortListAlphabetical(assigneeData)}
      />
      <Stack direction="row" justifyContent="flex-end">
        <Button onClick={onSubmit} disabled={userIsOwner && !userIsAdmin}>
          Add Owner
        </Button>
      </Stack>
    </Box>
  );
};

export default AddAssignee;
