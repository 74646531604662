import React, { useEffect, useMemo, useState } from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import ApprovalTable from './ApprovalTable/ApprovalTable';
import CreateApprovalComponent from './CreateApprovalForm/CreateApprovalComponent';
import { getSessionStorage } from '../../Authentication/Actions/authentication';
import { IObject } from '../../interfaces/IObject';
import { a11yProps } from '../../RiverusUI/Components/CustomTabPanel';
import ReusableConfirmationModal from '../../RiverusUI/Components/ReusableConfirmationModal';
import { deleteApproval, fetchRequestApprovals } from '../../Services/Approval';
import { QueryKeyGenerator } from '../../Utils/QueryKeyGenerator';

interface Props {
  pageWatcher: (page: string) => void;
}

const ApprovalTableTabs = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Contract Requests',
    value: 'contract_request',
  },
  {
    label: 'Contract Conditions',
    value: 'contract_condition',
  },
  {
    label: 'Pre Signatures',
    value: 'pre_signatory_approval',
  },
];

const ApprovalsComponent: React.FC<Props> = ({ pageWatcher }) => {
  const user_data = React.useMemo(() => getSessionStorage('user_profile'), []);
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [pageNumber, setPageNumberChange] = useState<number>(0);
  const [sorting, setSorting] = useState<GridSortModel>([]);
  const [filters, setFilters] = useState<IObject>({
    approval: null,
    contract: null,
    created_by: null,
    groups: null,
    contract_category: null,
  });
  const [activeTab, setActiveTab] = React.useState('all');
  const [openCreateApproval, setOpenCreateApproval] = useState<boolean>(false);
  const [approvalTableKey, setApprovalTableKey] = useState<number>(0);

  const [editDetails, setEditDetails] = useState<boolean>(false);
  const [editCopyDetailId, setEditCopyDetailId] = useState<any>();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
  const [associatedPolicy, setAssociatedPolicy] = useState<string[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteDocId, setDeleteDocId] = useState<any>('');

  const handleClose = () => {
    setEditCopyDetailId(null);
    setEditDetails(false);
    setOpenCreateApproval(false);
  };

  const queryClient = useQueryClient();

  useEffect(() => {
    pageWatcher('approvals');
  }, [pageWatcher]);

  const handleCloseDeleteConfirmation = () => {
    setDeleteDocId('');
    setOpenDeleteModal(false);
  };

  const { mutate: deleteApprovalMutation, isSuccess } = useMutation({
    mutationFn: deleteApproval,
    onSuccess: (responseData) => {
      const associatedWithDraft =
        responseData?.data?.associated_with_draft_approvals;
      queryClient.invalidateQueries({
        queryKey: QueryKeyGenerator.getRequestApproval(),
      });
      if (associatedWithDraft?.length > 0) {
        setIsOpenDeleteModal(true);
        setAssociatedPolicy(associatedWithDraft);
      } else {
        handleCloseDeleteConfirmation();
        enqueueSnackbar('Delete policy successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
  });

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      QueryKeyGenerator.getRequestApproval(),
      activeTab,
      pageNumber,
      filters,
      sorting,
      isSuccess,
      approvalTableKey,
    ],
    queryFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }
      let sortingParam = '';
      if (sorting.length > 0) {
        for (let i = 0; i < sorting.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}&ordering=${sorting[i].field}`;
          } else {
            sortingParam = `${sortingParam}&ordering=-${sorting[i].field}`;
          }
        }
      }
      let params = '';
      const page = pageNumber + 1;
      params = `${'?page=' + page}${
        activeTab !== 'all' ? `&approval_type=` + activeTab : ''
      }${sortingParam}${filterParam}`;
      return fetchRequestApprovals(params);
    },
  });

  const userAdminRole = useMemo(() => {
    return user_data?.roles.includes('admin');
  }, [user_data]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setPageNumberChange(0);
    setActiveTab(newValue);
    setFilters({});
  };

  const handleDelete = React.useCallback(
    (ids: GridRowId[]) => {
      if (ids.length > 0) {
        setOpenDeleteModal(true);
        setDeleteDocId(ids);
      }
    },
    [deleteApprovalMutation]
  );

  const handleEdit = (id: GridRowId) => {
    setEditDetails(true);
    setEditCopyDetailId(id);
    setOpenCreateApproval(true);
  };

  const handleCopy = (id: GridRowId) => {
    setEditCopyDetailId(id);
    setOpenCreateApproval(true);
  };

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
    handleCloseDeleteConfirmation();
  };

  const renderTable = React.useCallback(
    () => (
      <ApprovalTable
        key={approvalTableKey}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        data={data}
        isLoading={isLoading || isFetching}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setFilters={setFilters}
        setSorting={setSorting}
        deleteApproval={handleDelete}
        editApproval={handleEdit}
        copyApproval={handleCopy}
        filters={filters}
        userAdminRole={userAdminRole}
      />
    ),
    [
      data,
      handleDelete,
      isFetching,
      isLoading,
      pageNumber,
      selectedRow,
      approvalTableKey,
    ]
  );
  return (
    <Stack>
      <Stack className="sidebar-right-column" gap="16px">
        <Stack sx={{ paddingLeft: '16px' }} gap="16px">
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'riPrimary.500',
              padding: '8px 16px',
              borderRadius: '6px',
              fontSize: '11px',
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1,
              alignItems: 'center',
              '&:hover': {
                backgroundColor: 'riPrimary.500',
              },
              '&:disabled': {
                border: '2px solid rgba(0, 0, 0, 0.26)',
                background: 'unset',
              },
            }}
            onClick={() => {
              setOpenCreateApproval(true);
            }}
            disabled={!userAdminRole}
          >
            <AddCircleOutlineRoundedIcon fontSize="small" />
            <br />
            New Approval
          </Button>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
              {selectedRow.length ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                >
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => handleDelete(selectedRow)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ) : (
                <Stack direction="column" alignItems="start">
                  <IconButton
                    onClick={() => setApprovalTableKey(Math.random())}
                  >
                    <RefreshIcon />
                  </IconButton>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Approval tabs"
                  >
                    {ApprovalTableTabs.map((tab: any, index: number) => (
                      <Tab
                        key={index}
                        label={tab.label}
                        value={tab.value}
                        {...a11yProps(tab.value)}
                      />
                    ))}
                  </Tabs>
                </Stack>
              )}
            </Box>
            {renderTable()}
          </Box>
        </Stack>
      </Stack>
      {openCreateApproval && (
        <CreateApprovalComponent
          open={openCreateApproval}
          onClose={handleClose}
          editDetails={editDetails}
          editCopyDetailId={editCopyDetailId}
        />
      )}
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteConfirmation}
          title="Delete Policy"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => deleteApprovalMutation(deleteDocId)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected policy will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
      {isOpenDeleteModal && (
        <ReusableConfirmationModal
          open={isOpenDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Dialog"
          renderAction={
            <Stack alignItems="end">
              <Button
                onClick={handleCloseDeleteModal}
                variant="contained"
                style={{ padding: '5px 15px' }}
              >
                Ok
              </Button>
            </Stack>
          }
        >
          <Stack spacing={1}>
            <Typography>
              {associatedPolicy?.length === 1 ? 'This' : 'These'} policy cannot
              be deleted.
            </Typography>
            <Typography>because drafts are associated with it.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </Stack>
  );
};

export default ApprovalsComponent;
