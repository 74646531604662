import React, { useEffect, useMemo, useState } from 'react';

import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { Button, Grid } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';

import { TemplateDrawer } from './TemplateDrawer';
import { getSessionStorage } from '../../../Authentication/Actions/authentication';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  fetchEarlierDrafts,
  fetchExecutedContracts,
} from '../../../Services/Draft';

interface Props {
  selectedContractId: string;
  selectedContractName: string;
  selectedDisplayName: string;
  name: string;
  control?: any;
  options?: any;
  loading?: boolean;
  type?: string;
  valueKey?: string;
  setSelectedLinkedDraft?: any;
}

const LinkSelectedTemplateField: React.FC<Props> = ({
  selectedContractId,
  selectedContractName,
  name,
  control,
  options,
  loading = false,
  type = 'template',
  selectedDisplayName,
  valueKey,
  setSelectedLinkedDraft,
}) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPageCount, setTotalPageCount] = useState<number>(1);
  const [pageNumberEarlier, setPageNumberEarlier] = useState<number>(1);
  const [totalPageCountEarlier, setTotalPageCountEarlier] = useState<number>(1);
  const [selectedTabValue, setSelectedTabValue] =
    useState<string>('earlier_drafts');
  const { watch } = useFormContext();
  const linked_draft = watch('linked_draft') || '';

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const user_data = React.useMemo(() => getSessionStorage('user_profile'), []);

  const userGroups = useMemo(
    () =>
      user_data?.groups?.map((item: string) =>
        item === '/Legal & Sales' ? '/Legal' : item
      ),
    [user_data]
  );

  // to get Executed Contracts according to selected contract type.
  const {
    data: executedContractsData,
    isFetching: loadingExecutedContracts,
    fetchNextPage: fetchExecutedNextPage,
    hasNextPage: hasExecutedNextPage,
  } = useInfiniteQuery({
    queryKey: ['executed_contracts', selectedContractId],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await fetchExecutedContracts({
        id: selectedContractId,
        page: pageParam,
        page_size: 20,
        groups: `&groups=${userGroups?.join(',')}`,
      });
      const pageCount = Math.ceil(response.count / 20);
      setTotalPageCount(pageCount);
      setPageNumber(pageParam + 1);
      return {
        getExecutedContracts: response?.results ?? [],
        nextPage: pageParam + 1,
        totalPages: pageCount,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    enabled: openDrawer && !!(selectedContractId && type === 'draft'),
  });

  // to get Earlier Drafts according to selected contract type
  const {
    data: earlierDraftsData,
    isFetching: loadingEarlierDrafts,
    fetchNextPage: fetchEarlierNextPage,
    hasNextPage: hasEarlierNextPage,
  } = useInfiniteQuery({
    queryKey: ['earlier_draft', selectedDisplayName],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await fetchEarlierDrafts({
        name: selectedDisplayName,
        page: pageParam,
        page_size: 20,
        groups: `&groups=${userGroups?.join(',')}`,
      });
      const pageCount = Math.ceil(response.count / 20);
      setTotalPageCountEarlier(pageCount);
      setPageNumberEarlier(pageParam + 1);
      return {
        getEarlierDrafts: response?.results ?? [],
        nextPage: pageParam + 1,
        totalPages: pageCount,
      };
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    enabled: openDrawer && !!(selectedContractId && type === 'draft'),
  });

  const draftList = useMemo(() => {
    const executedContracts =
      executedContractsData?.pages?.flatMap(
        (page: any) => page?.getExecutedContracts
      ) || [];
    const earlierDrafts =
      earlierDraftsData?.pages?.flatMap(
        (page: any) => page?.getEarlierDrafts
      ) || [];
    return [...executedContracts, ...earlierDrafts];
  }, [executedContractsData, earlierDraftsData]);

  const documentCount = useMemo(() => {
    if (type === 'template') {
      return options?.length || 0;
    } else {
      return draftList?.length || 0;
    }
  }, [draftList, options, type]);

  useEffect(() => {
    const draft = draftList.filter((data: any) => data.id === linked_draft);
    setSelectedLinkedDraft?.(draft[0]);
  }, [draftList, linked_draft, setSelectedLinkedDraft]);

  const label_key = useMemo(() => {
    if (type === 'template') {
      return 'name';
    } else if (type === 'draft' && selectedTabValue === 'earlier_drafts') {
      return 'contractName';
    } else if (type === 'draft') {
      return 'file_name';
    } else {
      return '';
    }
  }, [type, selectedTabValue]);

  const helperText: string = useMemo(() => {
    if (selectedContractName) {
      return `${documentCount} ${type} found under contract type “${selectedContractName}”`;
    } else {
      return 'Please select any contract type';
    }
  }, [selectedContractName, documentCount]);

  return (
    <>
      <Grid container alignItems="start" mt={2}>
        <Grid item sm={9}>
          <RISelectComponent
            isShowDropDown={type === 'draft'}
            label={`Select ${type}`}
            required={type !== 'draft'}
            name={name}
            control={control}
            options={type === 'template' ? options || [] : draftList || []}
            labelKey={label_key}
            valueKey={valueKey}
            textFieldProps={{
              helperText: helperText,
            }}
            loading={
              loading || loadingExecutedContracts || loadingEarlierDrafts
            }
            renderOption={type === 'draft' ? () => null : undefined}
          />
        </Grid>
        <Grid item sm={3}>
          <Button
            variant="text"
            startIcon={<ManageSearchIcon />}
            onClick={() => {
              setOpenDrawer(true);
            }}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Find {type}
          </Button>
        </Grid>
      </Grid>
      <TemplateDrawer
        open={openDrawer}
        onClose={handleCloseDrawer}
        templateList={options}
        loadingTemplateList={
          loading || loadingEarlierDrafts || loadingExecutedContracts
        }
        control={control}
        name={name}
        executedContractsList={
          executedContractsData?.pages?.flatMap(
            (page: any) => page.getExecutedContracts
          ) || []
        }
        earlierDraftsList={
          earlierDraftsData?.pages?.flatMap(
            (page: any) => page.getEarlierDrafts
          ) || []
        }
        type={type}
        labelKey={label_key}
        fetchExecutedNextPage={fetchExecutedNextPage}
        fetchEarlierNextPage={fetchEarlierNextPage}
        hasExecutedNextPage={hasExecutedNextPage}
        hasEarlierNextPage={hasEarlierNextPage}
        loadingExecutedContracts={loadingExecutedContracts}
        loadingEarlierDrafts={loadingEarlierDrafts}
        totalPageCount={totalPageCount}
        pageNumber={pageNumber}
        totalPageCountEarlier={totalPageCountEarlier}
        pageNumberEarlier={pageNumberEarlier}
        selectedTabValue={selectedTabValue}
        setSelectedTabValue={setSelectedTabValue}
      />
    </>
  );
};

export default LinkSelectedTemplateField;
