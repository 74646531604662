import './Design/index';

import React from 'react';

import { ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import createSagaMiddleware from '@redux-saga/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import CacheBuster from 'react-cache-buster';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import packageInfo from '../package.json';
import aggregateWatcher from './Aggregates/Saga/AggregatesSaga';
import App from './App/Container/appCon';
import ClauseComponentWatcher from './DocumentView/Component/ClauseComponent/Saga/clauseComponentSaga';
import DocumentWatcher from './DocumentView/Saga/documentSaga';
import SnackbarCloseButton from './RiverusUI/Components/SnakbarCloseButton';
import theme from './RiverusUI/Theme/theme';
import searchPageWatcher from './Search/Saga/SearchSaga';
import * as serviceWorker from './serviceWorker';
import { mainReducer } from './Utils/MainReducer/mainReducer';

const sagaMiddleWare = createSagaMiddleware();
export const store = createStore(
  mainReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleWare))
);

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

sagaMiddleWare.run(DocumentWatcher);
sagaMiddleWare.run(searchPageWatcher);
sagaMiddleWare.run(aggregateWatcher);
sagaMiddleWare.run(ClauseComponentWatcher);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <CacheBuster currentVersion={packageInfo.version}>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <SnackbarProvider
              maxSnack={5}
              action={(snackbarKey) => (
                <SnackbarCloseButton snackbarKey={snackbarKey} />
              )}
            >
              <App />
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  </CacheBuster>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
